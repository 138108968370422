// =============================================================================
// PRIMARY STYLES
// =============================================================================

// apply a natural box layout model to all elements
html { box-sizing: border-box; }
*, *:before, *:after { box-sizing: inherit; }

html, button, input, select, textarea {
  font-family: $font;
  color: $cMain;
}

body {
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
  -webkit-font-smoothing: antialiased;
}

// =============================================================================
// Responsive base
// =============================================================================
// img { max-width: 100%; }
.container {
  width: 100%;
  max-width: $siteWidth;
  margin-left: auto;
  margin-right: auto;
  padding-left: $sidePadding;
  padding-right: $sidePadding;
}

// .page1022 {
//   min-height: 100%;
//   &:after {
//     content: '';
//     display: block;
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     top: 50%;
//     background-color: red;
//   }
// }

// .page1022 body {
//   position: relative;
//   z-index: 2;
// }


// =============================================================================
// HEADER
// =============================================================================

// NAVIGATION
// =============================================================================

// =============================================================================
// MAIN
// =============================================================================

// =============================================================================
// MISC and helpers
// =============================================================================

