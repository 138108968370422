$gallerySingleImageSize: 420px;
.gallery-grid {
  & > .container {
    lost-utility: clearfix;
    @include mq($until: $gallerySingleImageSize) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__item {
    display: block;

    @include mq($from: $gallerySingleImageSize) {
      @include aspect-ratio(168, 208);
    }
    @include mq($from: $siteWidth) {
      lost-waffle: 1/7 7 23px;
    }
    @include mq(1030px, $siteWidth) {
      lost-waffle: 1/6 6 20px;
    }
    @include mq(850px, 1030px) {
      lost-waffle: 1/5 5 20px;
    }
    @include mq(720px, 850px) {
      lost-waffle: 1/4 4 20px;
    }
    @include mq(515px, 720px) {
      lost-waffle: 1/3 3 20px;
    }
    @include mq(420px, 515px) {
      lost-waffle: 1/2 2 20px;
    }
    @include mq($until: $gallerySingleImageSize) {
      margin-bottom: 20px;
    }
    
    & > .content {
      background-color: transparent;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @include mq($until: $gallerySingleImageSize) {
        background: none;
      }
    }
    & > .content > a {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      @include mq($until: $gallerySingleImageSize) {
        position: static;
      }
      &:focus {
        outline: none;
      }
      & > img {
        display: block;
        width: 100%;
        height: auto;
        @include mq($from: $gallerySingleImageSize) {
          display: none;
        }
      }
    }
  }
  &__links {
    display: none;
  }
}