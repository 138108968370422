.icon {
  /* Flow with text content */
  display: inline-block;
  /* Use the parent font-size for width and height */
  height: 1em;
  width: 1em;
  /* Vertically align icon with adjacent text */
  vertical-align: middle;
  /* Align more nicely with capital letters */
  position: relative;
  top: -0.0625em;
  &.fill {
    /* Inherit the parent text color */
    fill: currentColor;
  }
  &.stroke {
    fill: none;
    stroke: currentColor;
  }
}