// VARIABLES
// =============================================================================
$cMain: #000;
$font: 'Objective', sans-serif;
$baseFontSize: 1em;
$baseLineHeight: 1.4;
$siteWidth: 1311px;
$sidePadding: 20px;
$siteWidth: 1311px + 2*$sidePadding;

// =============================================================================
// UTILITIES
// =============================================================================

// Image replacement
@mixin ir() {
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
}

// Semantic clearfix
@mixin clearfix() {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// List reset
@mixin list-reset() {
  list-style: none;
  margin: 0;
  padding: 0;
}

// Nav list
@mixin nav-list() {
  @include list-reset;
  @include clearfix;
  li {
    float: left;
    &:first-child { margin-left: 0; }
    &:last-child { margin-right: 0; }
  }
}

// bg cover
@mixin bg-cover() {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

// Aspect ratio
@mixin aspect-ratio($width, $height) {
  position: relative;
  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
    pointer-events: none;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// Row machine (margin in percentages!)
@mixin rowMachine($numPerRow, $margin) {
  width: ((100% - (($numPerRow - 1) * margin)) / $numPerRow);
  margin-bottom: $margin;
  margin-right: $margin;
  &:nth-child(#{$numPerRow}n) {
    margin-right: 0;
  }
}
