.publications {
  list-style: none;
  margin: 100px auto 0;
  padding: 0;
  width: 100%;
  max-width: 550px;
  text-align: center;
  font-size: 20px;
  line-height: 1.2;
  @include mq($until: 780px) {
    margin-top: 10px;
  }
  li {
    padding: 6px 0;
  }
  a {
    text-decoration: none;
    color: inherit;
    &:hover { text-decoration: underline; }
  }
}