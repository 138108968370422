.page-header {
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;
  & > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo-holder {
    position: relative;
    display: block;
    flex: 1;
    @include mq($until: 600px) {
      position: static;
    }
  }
  .logo {
    display: block;
    width: 100%;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
.hamburger {
  position: relative;
  display: block;
  width: 74px;
  height: 62px;
  margin-right: 62px;
  @include mq($until: 1066px) {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 30px;
    height: auto;
    align-self: stretch;
    width: 64px;
  }
  @include mq($until: 860px) {
    width: 44px;
  }
  @include mq($until: 554px) {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 20px;
    width: 34px;
  }
  &:before, &:after, span {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: percentage(1/5);
    background-color: #000;
    transition: all 0.3s linear;
    transform-origin: center;
  }
  &:before { top:0; }
  &:after { bottom:0; }
  span {
    opacity: 1;
    top: 50%;
    transform: translateY(-50%);
  }
}
.nav-open .hamburger {
  span { 
    opacity: 0;
    transform: rotate(-135deg);
  }
  &:before { 
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
  }
  &:after {
    bottom: 50%;
    transform: translateY(50%) rotate(45deg);
  }
}

.main-nav {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  pointer-events: none;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.3s ease-in;
  @include mq($until: 600px) {
    top: 100%;
    bottom: auto;
  }
  & > ul {
    width: 100%;
    max-width: 480px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
    @include mq($until: 600px) {
      display: block;
      max-width: 100%;
    }
    & > li {
      @include mq($until: 600px) {
        display: block;
        margin: 10px 0;
      }
      & > a {
        color: #171618;
        font-size: 28px;
        font-weight: 700;
        line-height: 1.2;
        text-decoration: none;
        text-transform: lowercase;
        transition: color 0.3s linear;
        @include mq($until: 600px) {
          display: block;
          text-align: center;
          padding: 26px 0;
        }
        &:hover { color: #ddd; }
      }
      &.active > a { color: #ddd; }
    }
  }
}

.nav-open {
  .main-nav {
    opacity: 1;
    pointer-events: all;
  }
}