.contact-page {
  min-height: calc(100vh - 145px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.contact {
  a {
    font-size: 20px;
    color: inherit;
    text-decoration: none;
  }
}